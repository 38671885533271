import { createApi } from "@reduxjs/toolkit/query/react";

import config from "@/utils/config";
import { createBaseQuery } from "@/lib";
import { methods } from "@/utils/constants";
import cacher from "@/utils/rtkQueryCacheUtils";

const tagType = "CHECKOUT";

const checkoutApi = createApi({
    baseQuery: createBaseQuery({ baseURL: `${config.apiURL}` }),
    tagTypes: [cacher.defaultTags, tagType],
    reducerPath: "checkoutApi",
    endpoints: (builder) => ({
        getCheckout: builder.query({
            query: ({ processor, transactionRef }) => ({
                url: `/payments/${processor}/params/${transactionRef}`,
                method: methods.GET,
            }),
            transformResponse: transformInfo,
        }),
        getDreamLabsCheckout: builder.query({
            query: ({ transactionRef, paymentInfo }) => ({
                url: `/payments/dreamlabs/${transactionRef}/${paymentInfo}`,
                methods: methods.GET,
            }),
            transformResponse: transformDreamlabsInfo,
        }),
        getReceiptInfo: builder.query({
            query: (trans_reference) => ({
                url: `/logs/${trans_reference}`,
                methods: methods.GET,
            }),
            transformResponse: transformReceiptInfo,
        }),
        getRedirectUrl: builder.query({
            query: (schoolConfig) => ({
                url: `/config/${schoolConfig}/REDIRECT_URL`,
                methods: methods.GET,
            }),
        }),
    }),
    refetchOnMountOrArgChange: true,
});

function transformInfo(info) {
    const trimmedCustomerName = info?.webPayLog?.customerName?.replace(/\s+/g, " ").trim();

    return {
        ...info.parameters,
        firstName: trimmedCustomerName.split(" ")[0],
        lastName: trimmedCustomerName.split(" ")[1],
        gatewayUrlAlt: info.gatewayUrl,
        customerName: trimmedCustomerName,
        amount: info.webPayLog.amount,
        charge: info.webPayLog.schoolConfig.charge,
        transactionRef: info.webPayLog.transactionRef,
        total: Number(info.totalAmount),
        sess1: info.sessionFrom,
        sess2: info.sessionTo,
        viewTotal: Number(info.webPayLog.amount) + Number(info.webPayLog.schoolConfig.charge),
        rrr: info.webPayLog.rrr,
        responseUrl: info.responseUrl,
        hash: info.hash,
        split: info.split,
        splitData: info.splitData,
        description: info.webPayLog.description,
        email: info.webPayLog.customerEmail,
        phone: info.webPayLog.customerPhoneNumber,
        customerId: info.webPayLog.customerId,
        level: info.level,
        department: info.department,
        faculty: info.faculty,
        notificationUrl: info.notificationUrl,
        schoolId: info.schoolId,
    };
}

function transformDreamlabsInfo(info) {
    if (info.status === "error") {
        return { failMessage: info.message };
    } else
        return {
            services: info.data?.services,
            customerName: info.transaction.customerName,
            transactionRef: info.transaction.transactionRef,
            rrr: info.transaction.rrr,
            total: +info.transaction.amount + +info.transaction.schoolConfig.charge,
            authUrl: info.data?.authorization_url,
            schoolName: info.transaction?.schoolConfig?.schoolInfo?.name,
        };
}

function transformReceiptInfo(info) {
    const redirectUrl = info?.customFields.find(
        (customField) => customField.name === "REDIRECT_URL"
    )?.value;
    const processor = info.schoolConfig.paymentPlatform.id.toLowerCase().split(" ").join("");
    const total = +info.amount + +info.schoolConfig.charge;
    return {
        customerName: info.customerName,
        transactionRef: info.transactionRef,
        amount: +info.amount,
        charge: +info.schoolConfig.charge,
        schoolConfig: info.schoolConfig.id,
        total,
        status: info.status,
        processor,
        responseCode: info?.responseCode,
        responseText: info?.responseText,
        redirectUrl,
    };
}

export const {
    useGetCheckoutQuery,
    useGetDreamLabsCheckoutQuery,
    useGetReceiptInfoQuery,
    useGetRedirectUrlQuery,
} = checkoutApi;
export default checkoutApi;
